import './index.css';
import { AuthContext } from '../../../../context/auth';
import paymentsService from '../../../../services/payments.service';
import { useState, useContext, useRef } from "react";
import { Alert, Col, Row, Form, Button, Modal } from "react-bootstrap";

const PaymentModal = (props) => {
    const auth = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [errors, setErrors] = useState({});
    const [coupon, setCoupon] = useState('');
    const couponRef = useRef(null);

    const handlePayment = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setErrors({});
        setAlert(null);

        let data = {
            idPlan: props.plan.id,
            coupon: isValidCoupon()
        }
        try {
            let payService = await paymentsService.pay(data);
            if (payService.status === 'success') {
                props.showQRCode(payService.data.idPay);
            }
            if (payService.status === 'error') {
                handleError(payService.error);
            }
        } catch (error) {
            console.log(error);
        }
    }

    function isValidCoupon() {
        if (coupon.length > 0){
            return coupon;
        }
        return null;
    }

    const handleError = (error) => {
        if (error === 'invalid token') {
            auth.setIsLoggedIn(false);
        }
        if (error === 'There is an order without payment') {
            setAlert('Existe um pagamento em aberto');
        }
        if (error === 'Invalid discount coupon') {
            setErrors({ coupon: 'Cupom de desconto inválido' });
        }
        if (error === 'Coupon incompatible with plan') {
            setErrors({ coupon: 'Cupom válido para o Plano I' });
        }
        if (error === 'Coupon already used') {
            setErrors({ coupon: 'Cupom já foi usado' });
        }
        setIsLoading(false);
    };

    function clearForm() {
        setAlert(null);
        setErrors({});
        setCoupon('');
        setIsLoading(false);
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="md"
            fullscreen="sm-down"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onExited={() => { clearForm() }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Forma de pagamento
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="payment-information">
                    <div className='icon'>
                        <svg viewBox="0 0 317 360">
                            <path d="M246.13,264.53A46.07,46.07,0,0,1,213.35,251L166,203.62a9,9,0,0,0-12.44,0l-47.51,47.51A46.09,46.09,0,0,1,73.27,264.7H64l60,60a48,48,0,0,0,67.81,0l60.12-60.13Z" fill="#32BCAD" />
                            <path d="M73.28,97.09a46.08,46.08,0,0,1,32.78,13.57l47.51,47.52a8.81,8.81,0,0,0,12.44,0l47.34-47.34a46,46,0,0,1,32.78-13.58h5.7L191.71,37.14a47.94,47.94,0,0,0-67.81,0L64,97.09Z" fill="#32BCAD" />
                            <path d="M301.56,147l-36.33-36.33a7,7,0,0,1-2.58.52H246.13a32.62,32.62,0,0,0-22.93,9.5L175.86,168a22.74,22.74,0,0,1-32.13,0L96.21,120.51A32.62,32.62,0,0,0,73.28,111H53a7.12,7.12,0,0,1-2.44-.49L14,147a48,48,0,0,0,0,67.81l36.48,36.48a6.85,6.85,0,0,1,2.44-.49H73.28a32.63,32.63,0,0,0,22.93-9.51l47.51-47.51c8.59-8.58,23.56-8.58,32.14,0l47.34,47.33a32.62,32.62,0,0,0,22.93,9.5h16.52a6.9,6.9,0,0,1,2.58.52l36.33-36.33a47.94,47.94,0,0,0,0-67.81" fill="#32BCAD" />
                        </svg>
                    </div>
                    <div className='text'>
                        <span>Pix</span>
                        <i>Aprovação imediata</i>
                    </div>
                    <div className="radio">
                        <Form.Check
                            checked
                            readOnly
                            type='radio'
                        />
                    </div>
                </div>
                {
                    alert !== null && (
                        <Alert variant='danger'>{alert}</Alert>
                    )
                }
                <Form id='formPayment' className="form-signup" onSubmit={handlePayment}>
                    <Row>
                        <Form.Group className="mb-3" controlId="formGroupPlan">
                            <Form.Label>Plano escolhido:</Form.Label>
                            <Form.Control
                                disabled
                                required
                                size="md"
                                type="text"
                                value={props.plan.description}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formGroupValue">
                                <Form.Label>Valor:</Form.Label>
                                <Form.Control
                                    disabled
                                    size="md"
                                    type="text"
                                    value={props.plan.value}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formGroupCupom">
                                <Form.Label>Cupom de desconto:</Form.Label>
                                <Form.Control
                                    isInvalid={Boolean(errors.coupon)}
                                    maxLength={20}
                                    onChange={(e) => { setCoupon(e.target.value) }}
                                    ref={couponRef}
                                    size="md"
                                    type="text"
                                    value={coupon}
                                />
                                <Form.Control.Feedback type="invalid">{errors.coupon}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={isLoading}
                    form='formPayment'
                    type='submit'
                    variant='primary'
                >
                    {
                        isLoading === true ?
                            <>
                                <span className="spinner-border spinner-border-sm me-3"></span>
                                <span>Aguarde</span>
                            </>
                            :
                            <span>Confirmar</span>
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default PaymentModal;